import React, { useState } from "react"
import ContactForm from "../contactForm/indexToday"
import ContactSupport from "../contactSupport"
import "./style.sass"

const ContactContent = () => {
  const [showForm, setShowForm] = useState(true)

  return (
    <div id="contact_content">
      <div className="container">
        <ContactForm />
      </div>
    </div>
  )
}

export default ContactContent
